import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';

import { BehaviorSubject, catchError, filter, first, Observable, switchMap, throwError } from 'rxjs';

import { ClientAuthenticationResponse } from '@core/models/clientAuthenticationResponse';
import { AuthService } from '@core/services/auth/auth.service';
import { TokenStorageService } from '@core/services/auth/token-storage.service';
import { TokenService } from '@core/services/auth/token.service';

const TOKEN_HEADER_KEY = 'Authorization';

@Injectable()
export class HttpTokenInterceptor implements HttpInterceptor {
  private authService: AuthService = inject(AuthService);
  private tokenService: TokenService = inject(TokenService);
  private tokenStorageService: TokenStorageService = inject(TokenStorageService);

  private isRefreshing = false;
  private refreshTokenSubject: BehaviorSubject<string> = new BehaviorSubject<string>(null);

  intercept(req: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    let authReq = req;
    const token = this.tokenStorageService.getToken();

    if (token) {
      authReq = HttpTokenInterceptor.addTokenHeader(req, token);
    } else {
      const contentTypeHeader = req.detectContentTypeHeader();
      if (contentTypeHeader?.startsWith('text/plain')) {
        authReq = HttpTokenInterceptor.addContentType(req);
      }
    }

    return next.handle(authReq).pipe(
      catchError(error => {
        if (error instanceof HttpErrorResponse && !authReq.url.includes('auth/token') && error.status === 401) {
          return this.handle401Error(authReq, next);
        }
        return throwError(error);
      })
    );
  }

  private handle401Error(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    if (!this.isRefreshing) {
      this.isRefreshing = true;
      this.refreshTokenSubject.next(null);
      const token = this.tokenStorageService.getRefreshToken();
      if (token) {
        return this.authService.refreshToken(token).pipe(
          switchMap((response: ClientAuthenticationResponse) => {
            this.isRefreshing = false;
            this.tokenService.setToken(response.access_token);
            this.refreshTokenSubject.next(response.access_token);

            return next.handle(HttpTokenInterceptor.addTokenHeader(request, response.access_token));
          }),
          catchError(error => {
            this.isRefreshing = false;
            this.tokenService.clear();
            return throwError(error);
          })
        );
      }
    }
    return this.refreshTokenSubject.pipe(
      filter((token: string) => !!token),
      first(),
      switchMap((token: string) => next.handle(HttpTokenInterceptor.addTokenHeader(request, token)))
    );
  }

  private static addTokenHeader(request: HttpRequest<unknown>, token: string) {
    return request.clone({
      setHeaders: {
        [TOKEN_HEADER_KEY]: 'Bearer ' + token,
        'Content-Type': 'application/json'
      }
    });
  }

  private static addContentType(request: HttpRequest<unknown>) {
    return request.clone({
      setHeaders: {
        'Content-Type': 'application/json'
      }
    });
  }
}
