import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import {
  enableProdMode,
  ErrorHandler,
  importProvidersFrom,
  provideExperimentalZonelessChangeDetection
} from '@angular/core';
import { bootstrapApplication } from '@angular/platform-browser';
import { provideAnimations } from '@angular/platform-browser/animations';
import { PreloadAllModules, provideRouter, withComponentInputBinding, withPreloading } from '@angular/router';
import { ServiceWorkerModule } from '@angular/service-worker';

import { provideAngularQuery, QueryClient } from '@tanstack/angular-query-experimental';

import { GlobalErrorHandler } from '@core/interceptors/global-error-handler';
import { HttpTokenInterceptor } from '@core/interceptors/http-token.interceptor';
import { LoadingInterceptor } from '@core/interceptors/loading.interceptor';
import { ServerErrorInterceptor } from '@core/interceptors/server-error.interceptor';

import rootRoutes from '@app/root-routes';
import { RootComponent } from '@app/root.component';

import { environment } from '@environments/environment';

if (environment.production) {
  enableProdMode();
}

bootstrapApplication(RootComponent, {
  providers: [
    provideExperimentalZonelessChangeDetection(),
    provideAngularQuery(new QueryClient()),
    provideAnimations(),
    provideRouter(rootRoutes, withComponentInputBinding(), withPreloading(PreloadAllModules)),
    provideHttpClient(withInterceptorsFromDi()),
    importProvidersFrom(
      ServiceWorkerModule.register('ngsw-worker.js', {
        enabled: environment.production && !environment.override_web_worker
      })
    ),
    {
      provide: ErrorHandler,
      useClass: GlobalErrorHandler
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ServerErrorInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpTokenInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: LoadingInterceptor,
      multi: true
    }
  ]
}).catch(err => console.error(err));
