import { HttpErrorResponse } from '@angular/common/http';
import { inject, Injectable, Signal } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';

import { fromEvent, map, merge, Observable, of } from 'rxjs';

import { RxStompState } from '@stomp/rx-stomp';

import { ClientErrorPayload } from '@core/models/clientErrorPayload';
import { ClientUser } from '@core/models/clientUser';
import { AuthService } from '@core/services/auth/auth.service';
import { UserService } from '@core/services/user.service';
import { WebsocketService } from '@core/services/websocket.service';

@Injectable({
  providedIn: 'root'
})
export class ErrorService {
  private websocketService: WebsocketService = inject(WebsocketService);
  private authService: AuthService = inject(AuthService);
  private userService: UserService = inject(UserService);

  private websocketConnectionState: Signal<RxStompState> = this.websocketService.connectionState.asReadonly();

  private onlineStatus$: Observable<boolean> = merge(
    of(navigator.onLine),
    fromEvent(window, 'online').pipe(map(() => true)),
    fromEvent(window, 'offline').pipe(map(() => false))
  );

  private onlineStatus: Signal<boolean> = toSignal(this.onlineStatus$);

  getErrorMessage(error: Error | HttpErrorResponse): ClientErrorPayload {
    console.error(error); /* @Rolf: Entwicklung: Fehlermeldungen auf der Konsole ausgeben */

    const currentUser: ClientUser = this.userService.currentUser();

    let message: string;
    let action: string;
    if (error instanceof HttpErrorResponse) {
      message = error.error.message || this.getServerErrorMessage(error);
      action = `Server error ${error.status}`;
    } else {
      message = error.message || error.toString();
      action = 'Client error';
    }
    return {
      message: message,
      user: currentUser?.name || 'anonymous',
      userAgent: navigator.userAgent || 'unknown',
      timestamp: Date.now(),
      route: window.location.pathname || 'unknown',
      action: action,
      authenticated: this.authService.authenticatedOrDashboardTokenSet(),
      online: this.onlineStatus(),
      wsState: RxStompState[this.websocketConnectionState()]
    };
  }

  private getServerErrorMessage(error: HttpErrorResponse): string {
    switch (error.status) {
      case 400:
        return 'Ungültige Anfrage. Bitte überprüfen Sie Ihre Eingaben.';
      case 401:
        return 'Nicht autorisiert. Bitte melden Sie sich erneut an.';
      case 403:
        return 'Unerlaubter Zugriff. Sie haben keine Berechtigung, auf diese Ressource zuzugreifen.';
      case 404:
        return 'Ressource nicht gefunden.';
      case 500:
        return 'Interner Serverfehler. Bitte versuchen Sie es später erneut.';
      default:
        return 'Ein unerwarteter Fehler ist aufgetreten.';
    }
  }
}
