import { HttpErrorResponse } from '@angular/common/http';
import { ErrorHandler, inject, Injectable, Injector } from '@angular/core';

import { EventData } from '@core/models/eventData';
import { ErrorService } from '@core/services/error.service';
import { EventBusService } from '@core/services/event-bus.service';
import { LoggingService } from '@core/services/logging.service';
import { NotificationService } from '@core/services/notification.service';

@Injectable()
export class GlobalErrorHandler implements ErrorHandler {
  private injector: Injector = inject(Injector);

  handleError(error: Error) {
    const errorService = this.injector.get(ErrorService);
    const logger = this.injector.get(LoggingService);
    const notifier = this.injector.get(NotificationService);
    const eventBusService = this.injector.get(EventBusService);

    const errorMessage = errorService.getErrorMessage(error);

    logger.logError(errorMessage);

    if (error instanceof HttpErrorResponse) {
      if (error.status === 401) {
        // Unauthorized
        eventBusService.emit(new EventData('logout', null));
      } else if (error.status === 403) {
        // Forbidden
        notifier.notify('error', 'Zugriff verweigert', errorMessage.message);
      } else if (error.status >= 500) {
        // Server Error
        notifier.notify('error', 'Server Fehler', errorMessage.message);
      } else {
        notifier.notify('error', 'Fehler', errorMessage.message);
      }
    } else {
      notifier.notify('error', 'Fehler', errorMessage.message);
    }
  }
}
